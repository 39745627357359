










































.oauth-auth-redirect {
  display: block;
}
